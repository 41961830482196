import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  List, 
  ListItem, 
  ListItemText, 
  CircularProgress, 
  IconButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Alert, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Tooltip 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import VisibilityIcon from '@mui/icons-material/Visibility';  
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 
import { format, isValid } from 'date-fns';

function InfoProvenientaDosar() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
  const { id } = useParams();
  const [provenienta, setProvenienta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentProvenienta, setCurrentProvenienta] = useState(null); 
  const [formIsValid, setFormIsValid] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const [files, setFiles] = useState([]);

  const [formState, setFormState] = useState({
    departament: '',
    dataActiune: '',
    detalii: '',
    fisier: null,
  });
  const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);

  const [administrateFisiereDialogOpen, setAdministrateFisiereDialogOpen] = useState(false);
  const [documenteProvenienta, setDocumenteProvenienta] = useState([]);
  const [isAddingOrEditingDocument, setIsAddingOrEditingDocument] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileInputCount, setFileInputCount] = useState(1);
  
  const apiUrl = window.API_URL;
  const { token } = verificareToken();

  const handleAddFileInput = () => {
    const newId = Math.max(...fileInputs.map((input) => input.id), 0) + 1;
    setFileInputs([...fileInputs, { id: newId }]);
  };
  
  const handleVizualizare = (doc) => {
    console.log(doc);
    const urlLaravel = window.API_URL.replace('/api/', '');
    const fileSuffix = `/fisiere/in/${id}/prov/`;

    if (doc && doc.id) {
        const fileUrl = `${urlLaravel}${fileSuffix}/${currentProvenienta}/${encodeURIComponent(doc.locatie)}`;
        window.open(fileUrl, '_blank');
    }
};

  useEffect(() => {
    fetchDocumenteProvenienta(); 
  }, []);
  
  const fetchDocumenteProvenienta = () => {
    fetch(`${apiUrl}infoDocumenteProvenienta/${currentProvenienta}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.documenteProvenientaDosar) {
          setDocumenteProvenienta(data.data.documenteProvenientaDosar);
        }
      })
      .catch((error) => {
        console.error('A apărut o eroare la obținerea documentelor!', error);
      });
  };
 
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}infoProvenientaDosar/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setProvenienta(data.data.provenientaDosar);
    } catch (error) {
      console.error('A apărut o eroare!', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, token, apiUrl]);

  const handleOpen = (provenienta) => {
    setIsAddingOrEditing(false);
    if (provenienta) {
      setFormState({
        departament: provenienta.departament || '',
        dataActiune: provenienta?.dataactiune ? new Date(provenienta.dataactiune) : new Date(),
        detalii: provenienta.detalii || '',
        fisier: null,
      });
      setCurrentProvenienta(provenienta);
    } else {
      setFormState({
        departament: '',
        dataActiune: currentDate,
        detalii: '',
        fisier: null,
      });
      setCurrentProvenienta(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentProvenienta(null);
    setFormState({
      departament: '',
      dataActiune: '',
      detalii: '',
      fisier: null,
    });
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setFormState((prevState) => {
      const newState = { ...prevState, [name]: value };
  
      const isValidDepartament = newState.departament.trim() !== '';
      const isValidDetalii = newState.detalii.trim() !== '';
      const isValidDate = newState.dataActiune && isValid(new Date(newState.dataActiune));
  
      setFormIsValid(isValidDepartament && isValidDetalii && isValidDate);
  
      return newState;  
    });
  };
  
  const handleFileInputChange = (e, index) => {
    const file = e.target.files[0];
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = file;
    setSelectedFiles(newSelectedFiles);
  
    setFormState((prevState) => ({
      ...prevState,
      [`fisier-${index}`]: file,
    }));
  };

  const handleAdaugaSauEditeaza = async () => {
    setIsAddingOrEditing(true);
  
    const isValidDepartament = formState.departament.trim() !== '';
    const isValidDetalii = formState.detalii.trim() !== '';
    const isValidDate = isValid(new Date(formState.dataActiune));
  
    if (!isValidDepartament || !isValidDetalii || !isValidDate) {
      setFormIsValid(false);
      setIsAddingOrEditing(false);
      return;
    }
  
    const dataActiuneFormatted = format(new Date(formState.dataActiune), 'yyyy-MM-dd');
    const formData = new FormData(); 
    selectedFiles.forEach((file, index) => {
      formData.append(`fisier[${index}]`, file);
    });
  
    formData.append('departament', formState.departament);
    formData.append('dataActiune', dataActiuneFormatted);
    formData.append('detalii', formState.detalii);
    formData.append('idDosar', id);
  
    const queryParams = new URLSearchParams({
      departament: formState.departament,
      dataActiune: dataActiuneFormatted,
      detalii: formState.detalii,
      idDosar: id,
    });

    try {
      if (currentProvenienta) {
        const response = await fetch(`${apiUrl}provenientaDosar/${currentProvenienta.id}?${queryParams.toString()}`, {
        
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          //body: formData, 
        });
        const data = await response.json();
        if (data && data.data && data.data.provenientaDosar) {
          setProvenienta((prevProvenienta) => {
            return prevProvenienta.map((provenienta) => {
              if (provenienta.id === currentProvenienta.id) {
                return data.data.provenientaDosar;
              }
              return provenienta;
            });
          });
        }
      } else {
        const response = await fetch(`${apiUrl}provenientaDosar`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const data = await response.json();
        setProvenienta((prevProvenienta) => [...prevProvenienta, data.data.provenientaDosar]);
      }
      handleClose();
      fetchData();
    } catch (error) {
      console.error('A apărut o eroare!', error);
      setIsAddingOrEditing(false);
    } finally {
      setIsAddingOrEditing(false);
      setSelectedFiles([]);
    }
  };

  const handleSterge = async (provenientaId) => {
    setIsAddingOrEditing(true);

    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await fetch(`${apiUrl}provenientaDosar/${provenientaId}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setProvenienta(provenienta.filter((item) => item.id !== provenientaId));
          Swal('Șters!', 'Proveniența a fost ștearsă.', 'success');
          fetchData();
        } catch (error) {
          console.error('A apărut o eroare la ștergere!', error);
        } finally {
          setIsAddingOrEditing(false);
        }
      } else {
        setIsAddingOrEditing(false);
      }
    });
  };

  const handleAdministrateFisiere = (provenientaId) => {
    setCurrentProvenienta(provenientaId);
    setFormState((prevState) => ({
        ...prevState,
        fisier: null,
      }));
    const idProvenientaDosar = provenienta.find((provenienta) => provenienta.id === provenientaId);
  
    fetch(`${apiUrl}infoDocumenteProvenienta/${provenientaId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.documenteProvenientaDosar) {
          setDocumenteProvenienta(data.data.documenteProvenientaDosar);
        }
        setAdministrateFisiereDialogOpen(true);
      })
      .catch((error) => {
        console.error('A apărut o eroare la obținerea documentelor!', error);
        setAdministrateFisiereDialogOpen(true);
      });
  };
  
  const handleCloseAdministrateFisiere = () => {
    setDocumenteProvenienta([]);
    setAdministrateFisiereDialogOpen(false);
    fetchData();
  };

  const handleStergeDocument = async (documentId) => {
    setIsAddingOrEditingDocument(true);
  
    Swal({
        title: 'Sigur doriți ștergerea?',
        text: 'Această acțiune nu poate fi anulată!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
              await fetch(`${apiUrl}documenteProvenienta/${documentId}`, {
                  method: 'DELETE',
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
            });
            setDocumenteProvenienta((prevDocumente) => prevDocumente.filter((doc) => doc.id !== documentId));
            Swal('Șters!', 'Documentul a fost șters.', 'success');
          } catch (error) {
              console.error('A apărut o eroare la ștergerea documentului!', error);
          } finally {
              setIsAddingOrEditingDocument(false);
          }
        } else {
          Swal('Anulat!', 'Documentul nu a fost șters.', 'info');
        }
      });
  };

  const handleFileInputChangeAdministrateFisiere = (event) => {
    const selectedFile = event.target.files[0];
  
    console.log(selectedFile.name); 
  
    setFormState((prevState) => ({
      ...prevState,
      fisier: selectedFile, 
    }));
  };
  
  const handleAdaugaFisier = async () => {
    setIsAddingOrEditingDocument(true);
  
    const formData = new FormData();
    formData.append(`fisier[]`, formState.fisier);
    formData.append('idProvenientaDosar', currentProvenienta);
  
    try {
      const response = await fetch(`${apiUrl}documenteProvenienta`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }
  
      const data = await response.json();
  
      if (!data.data || !data.data.documente) {
        throw new Error("Răspunsul nu conține 'documente'");
      }
  
      setDocumenteProvenienta((prevDocumente) => [...prevDocumente, ...data.data.documente]);
      setFiles((prevFiles) => [...prevFiles, ...data.data.documente]); 
      handleAdministrateFisiere();
    } catch (error) {
      console.error('A apărut o eroare la adăugarea fișierelor!', error);
    } finally {
      setIsAddingOrEditingDocument(false);
      setSelectedFiles([]);
      handleAdministrateFisiere(currentProvenienta);
    }
  };
  
  const [fileInputs, setFileInputs] = useState([{ id: 1 }]);
 
  const handleRemoveFileInput = (id) => {
    setFileInputs(fileInputs.filter((input) => input.id !== id));
  };
  if (loading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><CircularProgress /></Box>;
  }

  return (
    <div>
      <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }}>Proveniența dosarului</Typography>
        {areDrept(DreptDeModificareDosare) ? ( 
        <Button
          disabled={!areDrept(DreptDeModificareDosare)}
          onClick={() => {
            setIsAddingOrEditing(false);
            setFormIsValid(false);
            handleOpen(null);
          }}
          variant="contained"
          size="small"
          color="secondary"
          className='pull-right'
          startIcon={<AddIcon />}
        >
          Adăugare proveniență nouă
        </Button>
        ) : null }
      </div>
      {provenienta.length === 0 ? (
        <Alert severity="info">Nu există proveniență atașată dosarului.</Alert>
      ) : (
        <List>
          {provenienta.map((provenienta, index) => (
            <ListItem
              key={provenienta.id}
              className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
            >
              <ListItemText
                primary={provenienta.departament}
                secondary={
                  <Typography variant="body2">
                    <div>
                      Data acțiunii: {format(new Date(provenienta.dataactiune), 'dd-MM-yyyy')}
                    </div>
                    <div>
                      Detalii: {provenienta.detalii || '-'}
                    </div>
                    <div>
                        <a onClick={() => handleAdministrateFisiere(provenienta.id)} style={{cursor:'pointer'}}>
                            {provenienta.documente_provenienta_dosar.length === 0 ? 'Nu conține documente atașate' : (provenienta.documente_provenienta_dosar.length === 1 ? '1 document atașat' : `${provenienta.documente_provenienta_dosar.length} documente atașate`)}
                        </a>
                    </div>

                  </Typography>
                }
              />
                {areDrept(DreptDeModificareDosare) ? ( 
                <>
                  <Tooltip title="Editare">
                    <IconButton aria-label="editeaza" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleOpen(provenienta)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ștergere">
                    <IconButton aria-label="sterge" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleSterge(provenienta.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
                ) : null } 
              <Tooltip title="Administrați fișierele">
                <IconButton
                  aria-label="administra fisiere"
                  onClick={() => handleAdministrateFisiere(provenienta.id)}
                >
                  <AttachFileIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}

      {/* adăugare și editare */}
      <Dialog maxWidth="xl" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle className='titluModal' id="form-dialog-title">
          {currentProvenienta ? 'Editare proveniență' : 'Adăugare proveniență'}
        </DialogTitle>
        <DialogContent className='mt-3' style={{ minWidth: '300px' }}>
          <TextField
            autoFocus
            margin="dense"
            id="departament"
            label="Departament"
            type="text"
            fullWidth
            value={formState.departament}
            onChange={handleInputChange}
            onInput={handleInputChange} 
            name="departament" required
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Data acțiunii"
              value={formState.dataActiune ? new Date(formState.dataActiune) : null}
              onChange={(newValue) => {
                setFormState((prevState) => ({
                  ...prevState,
                  dataActiune: newValue,
                }));
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              inputFormat="dd/MM/yyyy" 
              format="dd/MM/yyyy" 
              mask="__/__/____"
              className='mt-2 maxWidth mb-3'
            />
          </LocalizationProvider>

          <TextField
            margin="dense"
            id="detalii"
            label="Detalii"
            type="text"
            fullWidth
            value={formState.detalii}
            onChange={handleInputChange}
            onInput={handleInputChange} 
            name="detalii" required 
          />
        <div className='mt-2' style={{ display: currentProvenienta ? 'none' : 'block' }}>
          {fileInputs.map((fileInput, index) => (
            <div key={fileInput.id} style={{display:'flex'}}>
                
                <Tooltip title="Eliminare fișier">
                <IconButton className='pull-right' style={{cursor:'pointer !important', flex:'1'}}
                    onClick={() => handleRemoveFileInput(fileInput.id)}
                    aria-label="remove"
                    color="secondary"
                >
                    <RemoveIcon />
                </IconButton> 
                </Tooltip>
             <div className="upload-btn-wrapper" >

                <button className="btnr" title="haga clic para cargar el archivo">Selectați un fișier</button>
                <span>
                 {formState[`fisier-${fileInput.id}`] ? formState[`fisier-${fileInput.id}`].name : 'Nici un fișier selectat'}
                </span>

                <input
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,.jpg,.jpeg,.avi,.mp4,.mpeg,.png"
                    id={`fisier-${fileInput.id}`}
                    className="fileUpload"
                    name={`fisier-${fileInput.id}`}
                    onChange={(e) => handleFileInputChange(e, fileInput.id)}
                    title="Selectați un fișier"  
                
                    />


              </div>
            </div>
            ))} 

           
            <Button
                onClick={handleAddFileInput}
                aria-label="adăugare fișier"
                variant="outlined"
                size="small"
                color="primary"
                className='text-center'
                startIcon={<AddIcon />}
            >
            Adăugare fișier suplimentar
            </Button>

        </div>
        </DialogContent>
        <DialogActions>
        <Button
        onClick={handleAdaugaSauEditeaza}
        color="success"
        disabled={isAddingOrEditing || !formIsValid}
        >
        {isAddingOrEditing ? 'Se procesează...' : (currentProvenienta ? 'Salvare' : 'Adăugare')}
        </Button>

          <Button onClick={handleClose} color="error">
            Anulare
          </Button>
        </DialogActions>
      </Dialog>

      {/* administrarea fișierelor */}
      <Dialog fullWidth open={administrateFisiereDialogOpen} onClose={handleCloseAdministrateFisiere}>
  <DialogTitle className='titluModal' id="form-dialog-title">Administrați fișierele</DialogTitle>
  <DialogContent>
    {documenteProvenienta.length === 0 ? (
      <Alert severity="info" className='mt-3 mb-3'>Nu există documente atașate. Puteți atașa documente mai jos.</Alert>
    ) : (
      <TableContainer component={Paper} className='mt-3 mb-3'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nume fișier</TableCell>
              <TableCell>Acțiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documenteProvenienta.map((document, index) => (
              <TableRow key={document.id} className={index % 2 === 0 ? 'list-item striped' : 'list-item'}>
                <TableCell>{document.locatie}</TableCell>
                <TableCell>
                <Tooltip title="Vizualizare">
                    <IconButton aria-label="vizualizare" onClick={() => handleVizualizare(document)}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                {areDrept(DreptDeModificareDosare) ? ( 
                <Tooltip title="Șterge">
                    <IconButton
                      disabled={!areDrept(DreptDeModificareDosare)}
                      aria-label="sterge document"
                      onClick={() => handleStergeDocument(document.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                </Tooltip>
                ) : null } 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
    
          <div style={{display:'flex'}}>
              {areDrept(DreptDeModificareDosare) ? ( 
                <div style={{flex:'8'}} className="upload-btn-wrapper" >
                    <button className="btnr" title="Click pentru a selecta un fișier">Selectați un fișier</button>
                    <span> 
                        {formState.fisier!== null && formState.fisier !== undefined
                        ? formState.fisier.name
                        : 'Nici un fișier selectat'}
                    </span>


                    <input
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,.jpg,.jpeg,.avi,.mp4,.mpeg,.png"
                    id={`fisier`}
                    className="fileUpload"
                    name={`fisier`}
                    onChange={handleFileInputChangeAdministrateFisiere}
                    title="Selectați un fișier"
                    />

                </div>   
              ) : null } 


              {areDrept(DreptDeModificareDosare) ? ( 
                  <Button style={{flex:'2'}}
                      onClick={handleAdaugaFisier}
                      color="warning"
                      size='medium'
                      disabled={isAddingOrEditingDocument}
                      disabled={!areDrept(DreptDeModificareDosare)}
                      >
                      {isAddingOrEditingDocument ? 'Se încarcă...' : 'Adăugare'}
                    </Button>
              ) : null }        
        
          </div>
        
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseAdministrateFisiere} color="primary">
      Renunțare
    </Button>
  </DialogActions>
</Dialog>

    </div>
  );
}

export default InfoProvenientaDosar;
