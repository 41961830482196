import React, { useState, useEffect } from 'react';
import { Autocomplete, createFilterOptions, TextField, Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Typography, Box, Grid, Alert } from '@mui/material';
import Swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ro';
import verificareToken from '../VerificareToken';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale';
moment.locale('ro');

function StadiuDosar() {
  const { id } = useParams(); 

  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';

    const { token } = verificareToken();
  
  const [idStadiu, setIdStadiu] = useState('');
  const dataCurenta = new Date().toISOString().split('T')[0];
  const [data, setData] = useState('');
  const [idinstanta, setIdinstanta] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [okPotOpri, setOkPotOpri] = useState(false);
  const [inputsEnabled, setInputsEnabled] = useState(false);
  const [adaugareStadiu, setAdaugareStadiu] = useState(false);
  const [stadii, setStadii] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [instanteLoaded, setInstanteLoaded] = useState(false);
  const [stadiuAles, setStadiuAles] = useState(null);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [idStadiuDosar, setIdStadiuDosar] = useState(null);  
  const [stadiuDosar, setStadiuDosar] = useState(null);  

  const [openModal, setOpenModal] = useState(false);
  const [openModalInstante, setOpenModalInstante] = useState(false);
  const [openModalCalitati, setOpenModalCalitati] = useState(false);

  // State pentru editare stadiu
  const [editingStadiuId, setEditingStadiuId] = useState('');
  const [editedStadiuName, setEditedStadiuName] = useState('');
  const [isAddingNewStadiu, setIsAddingNewStadiu] = useState(false);
  const [numeStadiuNou, setNumeStadiuNou] = useState('');
  const [isLoadingStadii, setIsLoadingStadii] = useState(false);
 
  //judet + instanta
  const [judete, setJudete] = useState([]);
  const [judetSelectat, setJudetSelectat] = useState('');
  const [instante, setInstante] = useState([]);
  const [instantaSelectata, setInstantaSelectata] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  //administrare instante pe judet
  const [editingInstantaId, setEditingInstantaId] = useState('');
  const [editedInstantaName, setEditedInstantaName] = useState('');
  const [isAddingNewInstanta, setIsAddingNewInstanta] = useState(false);
  const [numeInstantaNoua, setNumeInstantaNoua] = useState('');
  const [isLoadingInstante, setIsLoadingInstante] = useState(false);

  //calitati
  const [editingCalitateId, setEditingCalitateId] = useState('');
  const [editedCalitateName, setEditedCalitateName] = useState(''); 
  const [numeCalitateNou, setNumeCalitateNou] = useState('');
  const [calitati, setCalitati] = useState([]);
  const [isLoadingCalitati, setIsLoadingCalitati] = useState(false);
  const [isAddingNewCalitate, setIsAddingNewCalitate] = useState(false);
  const [numeCalitateNoua, setNumeCalitateNoua] = useState('');
  const [calitateSelectata, setCalitateSelectata] = useState('');
  const [idCalitateSelectata, setIdCalitateSelectata] = useState(null); 
  

  //editare stadiu
  const [newData, setNewData] = useState(new Date().toISOString());
  const [newStadiu, setNewStadiu] = useState(''); 
  const [newInstanta, setNewInstanta] = useState('');
  const [newSectii, setNewSectii] = useState('');
  const [newJudecatori, setNewJudecatori] = useState('');

    //identificare stadiu executare silita
    const numeStadiuSelectat = stadii.find(stadiu => stadiu.id === newStadiu)?.denumire || '';
    // verific daca stadiul ales este "Executare Silită"
    const esteExecutareSilita = numeStadiuSelectat.includes('Executare silită');
    //console.log('esteExecutareSilita', esteExecutareSilita);
    
//stadiu
const fetchDropdownOptions = async () => {
  try {
    const response = await fetch(`${window.API_URL}infoStadiiDosar/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (data.status === 200 && data.data.stadiiDosar.length > 0) {
      const sortedOptions = data.data.stadiiDosar.sort((a, b) => {
        const dateA = new Date(a.data);
        const dateB = new Date(b.data);
        return dateB - dateA;
      });

      const lastStadiu = sortedOptions[0];

      setIdStadiuDosar(data.data.stadiuCurent);
      setStadiuDosar(data.data.idStadiuCurent);
      setDataLoaded(true);
      
      console.log('ultimul stadiu: ', data.stadiuCurent);
      console.log('id-ul ultimului stadiu: ', data.id);
      setDropdownOptions(sortedOptions);
    } else {
      console.error('Nu am gasit stadiiDosar la acest dosar.');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

 // prima data iau stadiile
 const fetchStadiuDosarDetails = async (idStadiuDosar) => {
  try {
    const response = await fetch(`${window.API_URL}stadiiDosar/${idStadiuDosar}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (data.status === 200 && data.data.stadiuDosar) {
      const stadiuDosarData = data.data.stadiuDosar;
      
      //console.log("stadiuDosarData:", stadiuDosarData);
      setNewStadiu(stadiuDosarData.idStadiu);
      setNewData(stadiuDosarData.data);
      setJudetSelectat(stadiuDosarData.idJudet);
      setNewInstanta(stadiuDosarData.idInstanta);
      setNewSectii(stadiuDosarData.denumireSectie);   
      setCalitateSelectata(stadiuDosarData.idCalitate);  
      //console.log('setCalitateSelectata:', calitateSelectata);
      //console.log('newData:', newData);
      //console.log('newInstanta:', newInstanta);
      setNewJudecatori(stadiuDosarData.judecatori);
    } else {
      console.error('Nu am gasit stadiuDosar.');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

useEffect(() => {
  fetchDropdownOptions();
}, [id, token]); 

useEffect(() => {
  if (idStadiuDosar) {
    fetchStadiuDosarDetails(idStadiuDosar);
  }
}, [idStadiuDosar]);



 
//apoi stadiul
  const fetchStadiu = () => { 
    fetch(`${window.API_URL}infoStadii/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data && result.data.idStadiu && result.data.stadii.length > 0) {
          
          setIdStadiu(result.data.idStadiuDosar);
          setData(moment(result.data.data).format('YYYY-MM-DD'));
          setIdinstanta(result.data.idInstanta);
          setStadii(result.data.stadii); 
        }         

        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
      })
      .catch((error) => {
        console.error('Eroare preluare stadiu:', error);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (idStadiuDosar) {
      fetchStadiu(); 
    }
  }, [stadiuDosar]);
  
  // Funcția pentru deschiderea modalului
  const handleOpenModalCalitati = () => {
    setOpenModalCalitati(true);
    setIsAddingNewCalitate(true);
  };

  // Funcția pentru închiderea modalului
  const handleCloseModalCalitati = () => {
    setOpenModalCalitati(false);
    setIsAddingNewCalitate(false);
  };

  useEffect(() => {
    fetch(`${window.API_URL}judete`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Dacă ai un judet implicit (cum ar fi "--------"), îl poți adăuga aici
        const judeteCuImplicit = [
          { id: '', denumire: '--------' },
          ...data.data.judete,
        ];
        setJudete(judeteCuImplicit);
      })
      .catch((error) => {
        console.error('Eroare la aducerea judetelor:', error);
      });
  }, []);

  useEffect(() => {
    if (judetSelectat !== '') {
      fetch(`${window.API_URL}instanteJudet/${judetSelectat}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setInstante(data.data.instante);
          
          //console.log('intra aici 1 - am judetul');
        })
        .catch((error) => {
          console.error('Eroare la aducerea instantelor:', error);
        });
    } else {
      fetch(`${window.API_URL}instanteJudet/0`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setInstante(data.data.instante);
          //console.log('intra aici 1 - nu am judetul');
        })
        .catch((error) => {
          console.error('Eroare la aducerea instantelor:', error);
        }); 
    }
  }, [judetSelectat]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  
  const fetchCalitati = () => {
    setIsLoadingCalitati(true);
  
    const apiUrl = stadiuAles
      ? `${window.API_URL}calitatiByIdStadiu/${stadiuAles}`
      : `${window.API_URL}calitati/${idStadiuDosar}`;
  
    if (stadiuAles || idStadiuDosar) {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data && result.data.calitati && result.data.calitati.length > 0) {
            const calitatiData = result.data.calitati;
            setCalitati(calitatiData);
            console.log('calitatiData', calitatiData); 
          } else {
            console.log('Nu au fost găsite calități pentru id stadiu:', idStadiuDosar);
          }
          console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        })
        .catch((error) => console.error('Eroare la preluarea calităților:', error))
        .finally(() => setIsLoadingCalitati(false));
    }
  };
  
  useEffect(() => {
    if (idStadiuDosar || stadiuAles) {
      fetchCalitati();
    }
  }, [idStadiuDosar, stadiuAles]);
  


  const fetchInstante = () => {
    if (judetSelectat !== '') { 
      return fetch(`${window.API_URL}instanteJudet/${judetSelectat}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setInstante(result.data.instante);
  
          console.log(`Message: ${result.message}, Status: ${result.status}`);
   
            setInstanteLoaded(true);
           
          //console.log('intra aici 2 - Am judetul');
        })
        .catch((error) => console.error('Error fetching instante:', error))
        .finally(() => setLoading(false));
    } else {
      return fetch(`${window.API_URL}instanteJudet/0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setInstante(result.data.instante);
          
          //console.log('intra aici 2 - nu am judet');
  
          console.log(`Message: ${result.message}, Status: ${result.status}`);
   
            setInstanteLoaded(true);
           
        })
        .catch((error) => console.error('Error fetching instante:', error))
        .finally(() => setLoading(false));
    }
};

  
  useEffect(() => { 
    fetchDropdownOptions(); 
    //fetchInstante().then(() => setInstanteLoaded(true));
  }, [id, token]);
   
  const handleModifica = async () => {
    const camposNecompletate = [];

    if (!newData) {
      camposNecompletate.push('Dată');
    }
    if (!newStadiu) {
      camposNecompletate.push('Stadiu');
    }
    if (!calitateSelectata) {
      camposNecompletate.push('Calitate');
    }
    if (!newInstanta) {
      camposNecompletate.push('Instanță');
    }
    if (!newSectii) {
      camposNecompletate.push('Sectii');
    }
    //if (!newJudecatori) {
    //  camposNecompletate.push('Judecatori');
    //}
  
    let apiUrlEditare;
 
    const formattedNewData = moment(newData).format('YYYY-MM-DD');


    if (esteExecutareSilita) {
      apiUrlEditare = `${window.API_URL}stadiiDosar/${idStadiuDosar}?data=${formattedNewData}&idstadiu=${newStadiu}&idcalitate=${calitateSelectata ?? "null"}&executor=${nume}&nrDosar=${nrDosar}&sediu=${sediu}&banca=${banca}&iban=${iban}&telefon=${telefon}&email=${email}`;
    } else {
      
    if (camposNecompletate.length > 0) {
      const errorMessage = `Câmpurile următoare nu sunt completate: ${camposNecompletate.join(', ')}`;
      Swal('Atenționare', errorMessage, 'warning');
      return;
    }
  
      apiUrlEditare = `${window.API_URL}stadiiDosar/${idStadiuDosar}?data=${formattedNewData}&idstadiu=${newStadiu}&idinstanta=${newInstanta}&judecatori=${newJudecatori}&sectie=${newSectii}&idcalitate=${calitateSelectata ?? "null"}`;
    }


    try {
      const response = await fetch(apiUrlEditare, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }, 
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        Swal("Succes!", 'Stadiul a fost actualizat cu succes', 'success');
        fetchStadiu();
        fetchInstante();
        setOkPotOpri(true);
      } else {
        console.error('Eroare la actualizare stadiu');
      }
    } catch (error) {
      console.error('Eroare:', error);
    }
  };
  
  const handleAdaugaStadiu = async () => {
    const camposNecompletate = [];
    const formattedNewData = moment(newData).format('YYYY-MM-DD');

    if (!newData) {
      camposNecompletate.push('Dată');
    }
    if (!newStadiu) {
      camposNecompletate.push('Stadiu');
    }
    if (!calitateSelectata) {
      camposNecompletate.push('Calitate');
    }
    if (!newInstanta) {
      camposNecompletate.push('Instanță');
    }
    if (!newSectii) {
      camposNecompletate.push('Sectii');
    }
    //if (!newJudecatori) {
     // camposNecompletate.push('Judecatori');
    //}
  
  
    let apiUrl;
 
    if (esteExecutareSilita) {
      apiUrl = `${window.API_URL}stadiiDosar?iddosar=${id}&data=${formattedNewData}&idstadiu=${newStadiu}&idcalitate=${calitateSelectata}&executor=${nume}&nrDosar=${nrDosar}&sediu=${sediu}&banca=${banca}&iban=${iban}&telefon=${telefon}&email=${email}`;
    } else {
      
    if (camposNecompletate.length > 0) {
      const errorMessage = `Câmpurile următoare nu sunt completate: ${camposNecompletate.join(', ')}`;
      Swal('Atenționare', errorMessage, 'warning');
      return;
    }
      apiUrl = `${window.API_URL}stadiiDosar?iddosar=${id}&data=${formattedNewData}&idstadiu=${newStadiu}&idinstanta=${newInstanta}&judecatori=${newJudecatori}&sectie=${newSectii}&idcalitate=${calitateSelectata}`;
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }, 
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        Swal("Succes!", 'Stadiul a fost adăugat cu succes', 'success');

        setOkPotOpri(true);
        
        fetchDropdownOptions();
        setInputsEnabled(false);
        setAdaugareStadiu(false);
        fetchStadiu();  
        fetchInstante();

      } else {
        console.error('Eroare la adăugare stadiu');
      }
    } catch (error) {
      console.error('Eroare:', error);
    }
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
        setNewData(date.toISOString());
    } else {
        console.error("Data selectată este invalidă.");
    }
};

  const formattedDate = moment(newData).format("YYYY-MM-DD");
 
  const formatData = (dataString) => {
    if (!dataString || isNaN(new Date(dataString).getTime())) {
      return ''; 
    }
    
    const date = new Date(dataString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    return `${day}.${month}.${year}`;
  };
  


  const fetchDateStadiu = (idStadiuDosarParam) => {
    //aici
    return fetch(`${window.API_URL}stadiiDosar/${idStadiuDosarParam}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {

          console.log("Date la schimbare dropdown sus: ", result.data);
          setNewStadiu(result.data.stadiuDosar.idStadiu);
          setNewData(new Date(result.data.stadiuDosar.data));
          setJudetSelectat(result.data.stadiuDosar.idJudet);
          setNewInstanta(result.data.stadiuDosar.idInstanta);
          setNewSectii(result.data.stadiuDosar.denumireSectie); 
          setCalitateSelectata(result.data.stadiuDosar.idCalitate);
          
          console.log('setCalitateSelectata2', calitateSelectata);
          setNewJudecatori(result.data.stadiuDosar.judecatori);
  
          console.log(`Message: ${result.message}, Status: ${result.status}`);
   
            setInstanteLoaded(true);
           
        })
        .catch((error) => console.error('Error fetching instante:', error))
        .finally(() => setLoading(false));
};

  const handleDropdownChange = (event) => {
    
          setNewStadiu('');
          setNewData('');
          setJudetSelectat('');
          setNewInstanta('');
          setNewSectii('');
          setCalitateSelectata('');
          const newIdStadiuDosar = event.target.value;
          setIdStadiuDosar(newIdStadiuDosar);
          console.log('setCalitateSelectata3', calitateSelectata);
          setNewJudecatori('');
          console.log('idstadiudosarnou', idStadiuDosar); 
          fetchDateStadiu(newIdStadiuDosar);
    fetchStadiu();  
  };
  
  const handleStadiuNou = () => { 
    
          setInputsEnabled(true);
          setNewStadiu('');
          setNewData(dataCurenta);
          setJudetSelectat('');
          setNewInstanta('');
          setNewSectii('');
          setCalitateSelectata('');
          console.log('setCalitateSelectata4', calitateSelectata);
          setNewJudecatori(''); 

          setAdaugareStadiu(true);
  };
  
    const handleStadiuEditare = () => { 
      
      setAdaugareStadiu(false);
      setInputsEnabled(true);
      
      setNewStadiu('');
      setNewData('');
      setJudetSelectat('');
      setNewInstanta('');
      setNewSectii('');
      setCalitateSelectata('');
      console.log('setCalitateSelectata5', calitateSelectata);
      setNewJudecatori('');

      fetchDateStadiu(idStadiuDosar);
      fetchStadiu(); 
      
  };

 
  const handleDeleteStadiuDosarClick = () => { 
    handleDeleteStadiuDosar();
  };

  
  const handleAdminStadii = () => {
    setOpenModal(true);
    setIsAddingNewStadiu(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isEditingStadiu = (idStadiuToCheck) => {
    return idStadiuToCheck === editingStadiuId;
  };

  const getStadiuName = (idStadiuToGet) => {
    if (idStadiuToGet === editingStadiuId) {
      return editedStadiuName;
    } else {
      return stadii.find((stadiu) => stadiu.id === idStadiuToGet)?.denumire || '';
    }
  };

  const handleEditStadiu = (idStadiuToEdit) => {
    setEditingStadiuId(idStadiuToEdit);
    setEditedStadiuName(getStadiuName(idStadiuToEdit));
  };

  const handleEditStadiuName = (e) => {
    setEditedStadiuName(e.target.value);
  };

  const handleAddNewStadiu = async () => {
    setIsLoadingStadii(true);   
    if (!numeStadiuNou) {
      Swal('Atenționare', 'Introduceți numele stadiului!', 'warning');
      return;
    }
  
    try {
      const apiUrl = `${window.API_URL}stadii?denumire=${encodeURIComponent(numeStadiuNou)}&iddosar=${id}`;
      
      const response = await fetch(apiUrl, {
        method: 'POST',  
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        
        setIsAddingNewStadiu(false);
        setNumeStadiuNou('');
        fetchStadiu();  
      } else {
        console.error('Eroare la adăugarea stadiului');
      }
    } catch (error) {
      console.error('A apărut o eroare: ', error);
    } finally {
      setIsLoadingStadii(false);   
    }
  };
  
  const handleSaveStadiu = async (idStadiuToSave) => {
    try { 
  
      const response = await fetch(`${window.API_URL}stadii/${idStadiuToSave}?denumire=${editedStadiuName}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }, 
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        setEditingStadiuId('');  
        fetchStadiu();  
      } else {
        console.error('Eroare la actualizarea stadiului');
      }
    } catch (error) {
      console.error('Eroare:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingStadiuId('');
    setEditedStadiuName('');
  };


  const handleDeleteStadiu = (idStadiuToDelete) => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteStadiuAPI(idStadiuToDelete);
      }
    });
  };


  const handleDeleteStadiuAPI = async (idStadiuToDelete) => {
    try {
      const response = await fetch(`${window.API_URL}stadii/${idStadiuToDelete}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        fetchStadiu();  
      } else {
        console.error('Eroare la ștergerea stadiului');
      }
    } catch (error) {
      console.error('Eroare:', error);
    }
  };



  const handleDeleteStadiuDosar = () => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteStadiuDosarAPI();
      }
    });
  };
  const handleDeleteStadiuDosarAPI = async () => {
    try {
      const response = await fetch(`${window.API_URL}stadiiDosar/${idStadiuDosar}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
        fetchStadiu();  
        fetchDropdownOptions();
      } else {
        console.error('Eroare la ștergerea stadiului');
      }
    } catch (error) {
      console.error('Eroare:', error);
    }
  };
  

  
  const handleAdminInstante = () => {
    setOpenModalInstante(true);
    setIsAddingNewInstanta(true);
    
    fetchInstante();
  };
  const handleCloseModalInstante = () => {
    setOpenModalInstante(false);
  };
  const handleAddNewInstanta = async () => {
    setIsLoadingInstante(true);
    if (!numeInstantaNoua) {
      Swal('Atenționare', 'Introduceți numele instanței!', 'warning');
      return;
    }
  
    try {
      const apiUrl = `${window.API_URL}instante?denumire=${encodeURIComponent(numeInstantaNoua)}&idjudet=${judetSelectat}`;
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
  
        fetchInstante();
        setIsAddingNewInstanta(false);
        setNumeInstantaNoua('');
      } else {
        console.error('Eroare la adăugarea instanței');
      }
    } catch (error) {
      console.error('A apărut o eroare: ', error);
    } finally {
      setIsLoadingInstante(false);
    }
  };
  

const isEditingInstanta = (idInstantaToCheck) => {
  return idInstantaToCheck === editingInstantaId;
};

const getInstantaName = (idInstantaToGet) => {
  if (idInstantaToGet === editingInstantaId) {
    return editedInstantaName;
  } else {
    return instante.find((instanta) => instanta.id === idInstantaToGet)?.denumire || '';
  }
};

const handleEditInstanta = (idInstantaToEdit) => {
  setEditingInstantaId(idInstantaToEdit);
  setEditedInstantaName(getInstantaName(idInstantaToEdit));
};

const handleEditInstantaName = (e) => {
  setEditedInstantaName(e.target.value);
};

const handleSaveInstanta = async (idInstantaToSave) => {
  try {
    const response = await fetch(`${window.API_URL}instante/${idInstantaToSave}?denumire=${editedInstantaName}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const result = await response.json();
      console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
      setEditingInstantaId('');  
      fetchInstante();
    } else {
      console.error('Eroare la actualizarea instanței');
    }
  } catch (error) {
    console.error('Eroare:', error);
  }
};

const handleCancelEditInstanta = () => {
  setEditingInstantaId('');
  setEditedInstantaName('');
};

const handleDeleteInstanta = (idInstantaToDelete) => {
  Swal({
    title: 'Sigur doriți ștergerea?',
    text: 'Această acțiune nu poate fi anulată!',
    icon: 'warning',
    buttons: ['Anulează', 'Da, șterge!'],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      handleDeleteInstantaAPI(idInstantaToDelete);
    }
  });
};

const handleDeleteInstantaAPI = async (idInstantaToDelete) => {
  try {
    const response = await fetch(`${window.API_URL}instante/${idInstantaToDelete}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const result = await response.json();
      console.log(`Mesaj: ${result.message}, Status: ${result.status}`);
      fetchInstante();
    } else {
      console.error('Eroare la ștergerea instanței');
    }
  } catch (error) {
    console.error('Eroare:', error);
  }
};

 



const handleAddNewCalitate = async () => {
  setIsLoadingCalitati(true);
  if (!numeCalitateNoua) {
    Swal('Atenționare', 'Introduceți numele calității!', 'warning');
    return;
  }

  try {
    const apiUrl = `${window.API_URL}calitati?denumire=${encodeURIComponent(
      numeCalitateNoua
    )}&idstadiu=${stadiuDosar}`;

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const result = await response.json();
      console.log(`Mesaj: ${result.message}, Status: ${result.status}`);

      fetchCalitati();
      setIsAddingNewCalitate(false);
      setNumeCalitateNoua('');
    } else {
      console.error('Eroare la adăugarea calității');
    }
  } catch (error) {
    console.error('A apărut o eroare: ', error);
  } finally {
    setIsLoadingCalitati(false);
  }
};

const isEditingCalitate = (idCalitateToCheck) => {
  return idCalitateToCheck === editingCalitateId;
};

const getCalitateName = (idCalitateToGet) => {
  if (idCalitateToGet === editingCalitateId) {
    return editedCalitateName;
  } else {
    return calitati.find((calitate) => calitate.id === idCalitateToGet)?.denumire || '';
  }
};

const handleEditCalitate = (idCalitateToEdit) => {
  setEditingCalitateId(idCalitateToEdit);
  setEditedCalitateName(getCalitateName(idCalitateToEdit));
};

const handleEditCalitateName = (e) => {
  setEditedCalitateName(e.target.value);
};

const handleSaveCalitate = async (idCalitateToSave) => {
  try {
    const response = await fetch(
      `${window.API_URL}calitati/${idCalitateToSave}?denumire=${encodeURIComponent(
        editedCalitateName
      )}&idstadiu=${stadiuDosar}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }, 
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log(`Mesaj: ${result.message}, Status: ${result.status}`);

      setEditingCalitateId(null);
      fetchCalitati();
    } else {
      console.error('Eroare la salvarea calității');
    }
  } catch (error) {
    console.error('A apărut o eroare: ', error);
  }
};

const handleCancelEditCalitate = () => {
  setEditingCalitateId(null);
};


const handleDeleteCalitate = (idCalitateToDelete) => {
  Swal({
    title: 'Sigur doriți ștergerea?',
    text: 'Această acțiune nu poate fi anulată!',
    icon: 'warning',
    buttons: ['Anulează', 'Da, șterge!'],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      handleDeleteCalitateAPI(idCalitateToDelete);
    }
  });
};
const handleDeleteCalitateAPI = async (idCalitateToDelete) => {
  try {
    const response = await fetch(
      `${window.API_URL}calitati/${idCalitateToDelete}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log(`Mesaj: ${result.message}, Status: ${result.status}`);

      const updatedCalitati = calitati.filter((calitate) => calitate.id !== idCalitateToDelete);
      setCalitati(updatedCalitati);

      if (idCalitateSelectata === idCalitateToDelete) {
        if (updatedCalitati.length > 0) {
          setIdCalitateSelectata(updatedCalitati[0].id);
        } else {
          setIdCalitateSelectata(null);
        }
      }
    } else {
      console.error('Eroare la ștergerea calității');
    }
  } catch (error) {
    console.error('A apărut o eroare: ', error);
  }
};



const handleChangeData = (newValue) => {
  setNewData(newValue);
};


const handleChangeStadiu = (event) => {
  setNewStadiu(event.target.value); 
  setStadiuAles(event.target.value);
  fetchCalitati();
};

const handleChangeCalitate = (event) => {
  setCalitateSelectata(event.target.value);
};

const handleChangeInstanta = (event) => {
  setNewInstanta(event.target.value);
};

const handleChangeSectii = (event) => {
  setNewSectii(event.target.value);
};

const handleChangeJudecatori = (event) => {
  setNewJudecatori(event.target.value);
}; 


const [executori, setExecutori] = useState([]);
const [executorSelectat, setExecutorSelectat] = useState('');
const [nume, setNume] = useState('');
const [nrDosar, setNrDosar] = useState('');
const [sediu, setSediu] = useState('');
const [iban, setIban] = useState('');
const [telefon, setTelefon] = useState('');
const [email, setEmail] = useState('');
const [banca, setBanca] = useState('');

useEffect(() => {
  const fetchExecutori = async () => {
    try {
      const response = await fetch(`${window.API_URL}executori`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setExecutori(data.data.stadiiExecutare); 
      } else {
        console.error('Eroare la preluarea executorilor');
      }
    } catch (error) {
      console.error('Eroare la fetch:', error);
    }
  };
  setOkPotOpri(false);
  fetchExecutori();
}, [esteExecutareSilita, okPotOpri]); 

const handleInputChange = (fieldName, value) => {
  switch (fieldName) {
    case 'nume':
      setNume(value);
      break;
    case 'nrDosar':
      setNrDosar(value);
      break;
    case 'sediu':
      setSediu(value);
      break;
    case 'iban':
      setIban(value);
      break;
    case 'telefon':
      setTelefon(value);
      break;
    case 'email':
      setEmail(value);
      break;
    case 'banca':
      setBanca(value);
      break;
    default:
 
  }
};


const handleExecutorChange = (event, newValue) => {
  console.log('newValue', newValue);
  if (typeof newValue === 'string') {
    // Utilizatorul a introdus manual un nume
    setExecutorSelectat(newValue.inputValue);
    // Resetați celelalte câmpuri
    setNume('');
    setNrDosar('');
    setSediu('');
    setIban('');
    setTelefon('');
    setEmail('');
    setBanca('');
  } else if (newValue && newValue.inputValue) {
    // Utilizatorul a selectat o opțiune care nu există în lista, dar a introdus un nume valid
    setExecutorSelectat(newValue.inputValue);
  } else {
    // Utilizatorul a selectat un executor din lista
    setExecutorSelectat(newValue);
    setNume(newValue ? newValue.nume : '');
    setNrDosar(newValue ? newValue.numardosar : '');
    setSediu(newValue ? newValue.sediu : '');
    setIban(newValue ? newValue.iban : '');
    setTelefon(newValue ? newValue.telefon : '');
    setEmail(newValue ? newValue.email : '');
    setBanca(newValue ? newValue.banca : '');
  }
};

// Funcția pentru a crea noi valori în Autocomplete
const createOption = (inputValue) => {
  return { inputValue, nume: inputValue };
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.nume,
});


const handleRenuntare = () => { 
  setInputsEnabled(false);
  setAdaugareStadiu(false);
  fetchStadiu();  
};


  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      
        <div>
          <div>
      
          {dataLoaded ? ( 
            <FormControl style={{ minWidth: '200px', marginLeft: '10px', float:'left' }} fullWidth>
              <InputLabel id="stadiuDosar-label">Stadiu dosar curent</InputLabel>
              <Select
                
                style={{ minWidth: 170 }}
                labelId="stadiuDosar-label"
                label="Stadiu dosar curent"
                value={idStadiuDosar}
                onChange={handleDropdownChange}
              >
                {dropdownOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.numeStadiu} (Data: {formatData(option.data)})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null }
      

          </div>
          <br />
          {!dataLoaded ? ( 
          <div style={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
            <Alert severity='warning'>
              Dosarul nu conține nici un stadiu
            </Alert>
            <Button variant='contained' color="secondary" size="small" disabled={!areDrept(DreptDeModificareDosare)} onClick={handleStadiuNou}>Adăugare stadiu</Button>
            
          </div>
          ) : (
            <>
            {areDrept(DreptDeModificareDosare) ? ( 
              <div style={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
                <Button disabled={!areDrept(DreptDeModificareDosare)} onClick={handleStadiuNou}>Stadiu nou</Button>
                <Button disabled={!areDrept(DreptDeModificareDosare)} onClick={handleStadiuEditare}>Editare stadiu</Button>
                <Button disabled={!areDrept(DreptDeModificareDosare)} onClick={handleDeleteStadiuDosarClick}>Ștergere stadiu</Button>
              </div>
            ) : null }
            </>
          ) }
          <hr />
          <div>
           
        <Grid container spacing={2} alignItems="center" className='mt-1 mb-1'>
          <Grid item xs={12}>
 
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ro}>
                <DatePicker
                    label="Dată"
                    value={new Date(newData)}
                    onChange={(newDate) => handleDateChange(newDate)}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                    inputFormat="dd.MM.yyyy" 
                    format="dd.MM.yyyy" 
                    mask="__.__.____"
                    className='maxWidth'
                    disabled={!inputsEnabled} 
                />
            </LocalizationProvider>

          </Grid> 
        </Grid>

          <Grid container spacing={2} alignItems="center" className='mt-1 mb-1'>
          <Grid item xs={9}>
            <FormControl disabled={!inputsEnabled} fullWidth>
              <InputLabel htmlFor="stadiu">Stadiu</InputLabel>
              <Select
                value={newStadiu} //stadiuDosar
                onChange={handleChangeStadiu}
                label="Stadiu"
                style={{ minWidth: 170 }}
              >
                {stadii.map((stadiu) => (
                  <MenuItem key={stadiu.id} value={stadiu.id}>
                    {stadiu.denumire}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button disabled={!inputsEnabled} onClick={handleAdminStadii}>Administrare stadii</Button>
          </Grid>
        </Grid>

         
           <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Administrare Stadii</DialogTitle>
            <DialogContent>
                  <Box display="flex" alignItems="center">
                      <TextField
                      label="Nume Stadiu Nou"
                      value={numeStadiuNou} 
                      onChange={(e) => setNumeStadiuNou(e.target.value)}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      size="small"
                      disabled={isLoadingStadii}  
                      />

                      <IconButton color="primary" onClick={handleAddNewStadiu} disabled={isLoadingStadii}>
                      {isLoadingStadii ? (
                          <CircularProgress size={24} /> 
                      ) : (
                          <CheckIcon />
                      )}
                      </IconButton>
                  </Box> <Box style={{ overflow: 'auto', maxHeight: '300px' }}>
      

                  {stadii.map((stadiu) => (
                    <Box key={stadiu.id} display="flex" alignItems="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <Typography style={{ marginRight: '8px' }}>{stadiu.denumire}</Typography>
                      </Box>
                      <Box>
                    
                    {isEditingStadiu(stadiu.id) ? (
                          <>
                            <input
                              type="text"
                              value={getStadiuName(stadiu.id)}
                              onChange={(e) => handleEditStadiuName(e, stadiu.id)}
                            />
                            <IconButton color="primary" onClick={() => handleSaveStadiu(stadiu.id)}>
                              <CheckIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() => handleCancelEdit(stadiu.id)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton color="primary" onClick={() => handleEditStadiu(stadiu.id)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() => handleDeleteStadiu(stadiu.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Renunțare</Button>
            </DialogActions>
          </Dialog>

        
          <Grid container spacing={2} alignItems="center" className='mt-1 mb-1'>
            <Grid item xs={9}>
              <FormControl disabled={!inputsEnabled} fullWidth>
                <InputLabel htmlFor="calitate">Calitate client</InputLabel>
                <Select
                  value={calitateSelectata}
                  onChange={handleChangeCalitate}
                  label="Calitate client"
                >
                  {calitati.map((calitate) => (
                    <MenuItem key={calitate.id} value={calitate.id}>
                      {calitate.denumire}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          <Grid item xs={3}>
            <Button disabled={!inputsEnabled} onClick={handleOpenModalCalitati}>Administrare calități</Button>
          </Grid>
          </Grid>
          {/* modal pentru administrare calitati */}
          <Dialog open={openModalCalitati} onClose={handleCloseModalCalitati}>
            <DialogTitle>Administrare Calități</DialogTitle>
            <DialogContent>
              <Box display="flex" alignItems="center">
                <TextField
                  label="Nume Calitate Nouă"
                  value={numeCalitateNoua}
                  onChange={(e) => setNumeCalitateNoua(e.target.value)}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                  disabled={isLoadingCalitati}
                  autoComplete="off"
                />

                <IconButton color="primary" onClick={handleAddNewCalitate} disabled={isLoadingCalitati}>
                  {isLoadingCalitati ? (
                    <CircularProgress size={24} />
                  ) : (
                    <CheckIcon />
                  )}
                </IconButton>
              </Box>

              <Box style={{ overflow: 'auto', maxHeight: '300px' }}>
                {calitati.map((calitate) => (
                  <Box key={calitate.id} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Typography style={{ marginRight: '8px' }}>{calitate.denumire}</Typography>
                    </Box>
                    <Box>
                      {isEditingCalitate(calitate.id) ? (
                        <>
                          <input
                            type="text"
                            value={getCalitateName(calitate.id)}
                            onChange={(e) => handleEditCalitateName(e, calitate.id)}
                          />
                          <IconButton color="primary" onClick={() => handleSaveCalitate(calitate.id)}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton color="error" onClick={() => handleCancelEditCalitate(calitate.id)}>
                            <CloseIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton color="primary" onClick={() => handleEditCalitate(calitate.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error" onClick={() => handleDeleteCalitate(calitate.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModalCalitati}>Renunțare</Button>
            </DialogActions>
          </Dialog>


          {esteExecutareSilita ? ( 
         <Grid container spacing={2} alignItems="center" className="mt-1 mb-2">
         {/* campuri daca e  "Executare Silită" */}
         <Grid item xs={8}>
          <TextField
            fullWidth
            label="Denumire executor"
            value={nume}
            variant="outlined"
            onChange={(e) => handleInputChange('nume', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={executori}
            getOptionLabel={(option) => option.nume || ''}
            value={executorSelectat}
            onChange={handleExecutorChange}
            filterOptions={filterOptions}
            //freeSolo // permite utilizatorilor să introducă orice text
            renderInput={(params) => (
              <TextField {...params} label="Executor" variant="outlined" fullWidth />
            )}
          />

        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nr. dosar"
            value={nrDosar}
            variant="outlined"
            onChange={(e) => handleInputChange('nrDosar', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Sediu"
            multiline
            rows={2} 
            value={sediu}
            variant="outlined"
            onChange={(e) => handleInputChange('sediu', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={banca}
            label="Banca"
            variant="outlined"
            onChange={(e) => handleInputChange('banca', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Iban"
            value={iban}
            variant="outlined"
            onChange={(e) => handleInputChange('iban', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Telefon"
            value={telefon}
            variant="outlined"
            onChange={(e) => handleInputChange('telefon', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Email"
            value={email}
            variant="outlined"
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </Grid>
        </Grid>

    ) : (
      <>
      {/* Alte stadii */}
      <Grid container spacing={2} alignItems="center" className="mt-1 mb-2">
        <Grid item xs={12}>
          <Typography variant="h7" style={{ marginBottom: '8px' }}>
            Instanța
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth disabled={!inputsEnabled}>
            <InputLabel htmlFor="judet">Judet</InputLabel>
            <Select
              value={judetSelectat}
              onChange={(e) => setJudetSelectat(e.target.value)}
              label="Judet"
            >
              {judete.map((judet) => (
                <MenuItem key={judet.id} value={judet.id}>
                  {judet.denumire}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FormControl disabled={!judetSelectat || !inputsEnabled} fullWidth>
              <InputLabel htmlFor="instanta">Instanța</InputLabel>
              <Select
                value={newInstanta}
                onChange={handleChangeInstanta}
                label="Instanța"
                style={{ minWidth: 170 }}
                required
              >
                {instante.map((instanta) => (
                  <MenuItem key={instanta.id} value={instanta.id}>
                    {instanta.denumire}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={handleAdminInstante} disabled={!judetSelectat || !inputsEnabled}>
            Administrare instanțe
          </Button>
        </Grid>
      </Grid>

        {/* Dialog pentru administrarea instantelor */}
      <Dialog open={openModalInstante} onClose={handleCloseModalInstante}>
          <DialogTitle>Administrare Instanțe</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center">
              <TextField
                label="Nume Instanță Nouă"
                value={numeInstantaNoua}
                onChange={(e) => setNumeInstantaNoua(e.target.value)}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                disabled={isLoadingInstante}
              />

              <IconButton color="primary" onClick={handleAddNewInstanta} disabled={isLoadingInstante}>
                {isLoadingInstante ? (
                  <CircularProgress size={24} />
                ) : (
                  <CheckIcon />
                )}
              </IconButton>
            </Box>
            <Box style={{ overflow: 'auto', maxHeight: '300px' }}>
          {instante.map((instanta) => (
            <Box key={instanta.id} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography style={{ marginRight: '8px' }}>{instanta.denumire}</Typography>
              </Box>
              <Box>
                {isEditingInstanta(instanta.id) ? (
                  <>
                    <input
                      type="text"
                      value={getInstantaName(instanta.id)}
                      onChange={(e) => handleEditInstantaName(e, instanta.id)}
                    />
                    <IconButton color="primary" onClick={() => handleSaveInstanta(instanta.id)}>
                      <CheckIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleCancelEditInstanta(instanta.id)}>
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton color="primary" onClick={() => handleEditInstanta(instanta.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteInstanta(instanta.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalInstante}>Renunțare</Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={2} alignItems="center" className='mt-1 mb-1'>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!inputsEnabled} 
              value={newSectii}
              onChange={handleChangeSectii}
              label="Sectii"
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
    

        <Grid container spacing={2} alignItems="center" className='mt-1 mb-1'>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!inputsEnabled} 
              label="Judecători"
              multiline
              value={newJudecatori}
              onChange={handleChangeJudecatori}
              rows={4} 
              variant="outlined" 
            />
          </Grid>
        </Grid>
      </>
    )}

      
          
      </div> 
           
          <br />
          {adaugareStadiu ? (
            <Button disabled={!inputsEnabled} onClick={handleAdaugaStadiu} variant="contained" size="small" color='success'>
              Adăugare stadiu nou
            </Button>
          ) : (
            <Button disabled={!inputsEnabled} onClick={handleModifica} variant="contained" size="small" color='success'>
              Salvare modificări stadiu
            </Button>
          )}

        <Button
            color="error"
            variant="text"
            size="small"
            onClick={handleRenuntare}
            disabled={!inputsEnabled}
            style={{ marginLeft: 'auto' }}  
          >
            Renunțare
          </Button>
        </div>
     
    </div>
  );

}

export default StadiuDosar;
